/* eslint-disable @shapeshiftoss/logger/no-native-console */
export const renderConsoleArt = () => {
  console.info(`%c AltoFinance DAO`, 'color: #3761F9; font-size: 16px; font-family: monospace')
  console.info(
    `%c Join our Discord https://discord.gg/altofinance`,
    'color: #3761F9; font-size: 12px; font-family: monospace',
  )
  console.info(
    `%c See the Alto DAO overview https://snapshot/altofinance`,
    'color: #3761F9; font-size: 12px; font-family: monospace',
  )
  console.info(
    `%c Contribute on GitHub https://github.com/altofinance`,
    'color: #3761F9; font-size: 12px; font-family: monospace',
  )
  console.info(
    `%c
                                    :?Y           
                                .~JPGGP           
                              7PGGGGGG5           
                             .GGGGGGGG5           
                             .GGGGGGGG5           
                             .GGGGGGGG5           
                             .#BBGGGGG5           
                             .#&###BBG5           
                             .########G           
                     .!5B:   .#######&B           
                 .^JGBBBB:   .#######&B           
              :75BBBBBBBB:   .#######&B           
           :YGBBBBBBBBBBB:   .########G           
           J&###BBBBBBBBG.   .#####BBBP           
           J&######BB57:     .BBBBBBBBP           
           J&######&J        .BBBBBBBBP           
           J&######&?        .BBBBBBBBP           
           J&#######Y        .GBBBBBBBP           
           J&####BBGGGY~. .^!77?YPGBBBP           
           J##BBGGGGGGGGG?777777777J5GP           
           .!YGGGGGGGGGGG?7777777777~^.           
               :75GGGGGGG?777777!~:               
                  .~JPGGG?777~:.                  
                      :7P?^.                      
    `,
    'color: #3761F9; font-size: 12px; font-family: monospace',
  )
}
